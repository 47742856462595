/* Navbar */

.nav-link {
  color: #fff!important; /* Default text color */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and color */
}

.nav-link:hover {
  background-color: #f72c00; /* Theme color background on hover */
  color: #f7baba!important; /* Text color remains white on hover */
}

.navbar-dark .navbar-toggler-icon {
  background-image: none; /* Hide default toggler icon background */
}
/* About page */

.about-header {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Ensures the video covers the entire container */
  transform: translate(-50%, -50%);
  z-index: 1;
}

.overlay {
  position: relative;
  z-index: 2;
  color: #ffffff;
  max-width: 1200px; /* Limit the width for larger screens */
  margin: 0 auto; /* Center the content */
  padding: 20px; /* Add some padding */
  background: rgba(247, 44, 0, 0.8); /* Theme color with opacity */
}


/* Contact Page */
.contact-header {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Ensures the video covers the entire container */
  transform: translate(-50%, -50%);
  z-index: 1;
}

.overlay {
  position: relative;
  z-index: 2;
  color: #ffffff;
  max-width: 1200px; /* Limit the width for larger screens */
  margin: 0 auto; /* Center the content */
  padding: 20px; /* Add some padding */
}


.contact-info {
  padding-top: 200px;
  margin-top: 20px; /* Space between title and contact info */
  font-size: 1.2rem; /* Adjust font size for better readability */
 
}
.contact-info a {
  color: #ffffff; /* White color for links */
  text-decoration: underline; /* Underline for links */
}

.row {
  display: flex;
  flex-wrap: wrap; /* Ensures columns wrap on smaller screens */
}

.contact-info h2 {
  padding: 200px 0 20px 0; /* 200px top padding for headings */
}


/* Home Page Header */
.home-header {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #000; /* Fallback color if video fails to load */
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Ensures the video covers the entire container */
  transform: translate(-50%, -50%);
  z-index: 1;
}

.overlay {
  position: relative;
  z-index: 2;
  color: #ffffff;
}

/* Logo Area */
.logoarea {
  width: 150px;
}

/* Footer Styling */
.footer {
  background-color: #f72c00;
  color: #fff;
  padding: 2rem 0;
}

.footer h5 {
  padding-top: 30px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.footer p, .footer a {
  color: #ffffff;
  font-size: 0.9rem;
  text-decoration: none;
}

.footer a:hover {
  color: #ddd;
  text-decoration: none;
}

/* Social Media Links Styling */
.social-icons {
  display: flex;
  justify-content: center; /* Center the social icons */
}

.social-icon {
  width: 40px; /* Set the width of the circle */
  height: 40px; /* Set the height of the circle */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Makes the icon circular */
  background-color: rgba(255, 255, 255, 0.2); /* Slightly transparent background */
  margin: 0 10px; /* Space between icons */
  transition: background-color 0.3s; /* Smooth transition for hover */
}

.social-icon:hover {
  background-color: rgba(255, 255, 255, 0.5); /* Lighter on hover */
}

/* Justification and Alignment */
.text-justify {
  text-align: justify; /* Justify text alignment */
}

/* Copyright */
.footer .copyright {
  font-size: 0.8rem;
  margin-top: 1rem;
  text-align: center;
}

